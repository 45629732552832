.confirm {
  position: relative;

  &--loading:before,
  &--loading:after {
    content: '';
    z-index: 2;
    position: absolute;
  }

  &--loading:before {
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $cardsBg70;
    color: $cardsText;
    border-radius: 24px;
  }

  &--loading:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: red;
    animation: 3s rotate infinite linear;
  }
}

.confirm-image {
  width: 100%;
  border-radius: 8px;
}

.confirm-name {
  font-size: 24px;
  margin: 20px 0 10px;
}

.confirm-signing {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: $bg;

  & > * {
    width: 100%;
  }
}

.confirm-signing-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    color: $bgText;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 10px;
  }

  img {
    width: 64px;
    margin-top: -32px;
  }
}

.confirm-signing-complete {
  background: $accent;
  color: $accentText;
  text-align: center;
  line-height: 64px;
  font-size: 12px;
  text-transform: uppercase;
  position: fixed;
  bottom: 0%;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transition: 0.4s transform;

  &--active {
    transform: none;
  }

  svg {
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 6px 0 0;
  }

  .circle-check-check {
    stroke: $accent;
  }

  .circle-check-circle {
    fill: white;
  }
}
