.halo-content {
}

.halo-content-media {
  // border-top-right-radius: 24px;
  // border-top-left-radius: 24px;
  width: 100%;
}

.halo-content-media-loading {
  height: 240px;
  background: $cardsBgAlt;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 50px;
  }

  p {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 5px;
  }
}

.halo-content-title {
  font-size: 24px;
}

.halo-content-minter {
  margin: 12px 0;
  font-size: 14px;

  a {
    color: #0047ff;
  }

  a:hover {
    text-decoration: underline;
  }
}

.halo-content-minter-avatar-placeholder,
.halo-content-minter-avatar {
  display: inline-block;
  vertical-align: middle;
  margin: -2px 8px 0 0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: $cardsBgAlt;
}

.halo-content-subheading {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 8px;
}

.halo-content-description {
}

.halo-content-chip-id {
  font-size: 12px;
  word-break: break-all;
}
