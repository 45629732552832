.animated-halo-chip {
  width: 64px;
  position: relative;
  margin: 10px auto 10px;

  img,
  svg {
    z-index: 2;
    position: relative;
  }

  &:before,
  &:after {
    z-index: 1;
    content: '';
    width: 56px;
    height: 56px;
    border: 1px solid #f0f0f0;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: 3s grow infinite linear;
  }

  &:after {
    animation-delay: 1.5s;
  }
}

.animated-halo-chip-ring {
}
