@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes grow {
  0% {
    width: 74px;
    height: 74px;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 132px;
    height: 132px;
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

.page-transition-reversed .fadeIn {
  animation: 0.5s fadeInReverse forwards;
}

.page-transition-reversed .fadeOut {
  animation: 0.5s fadeOutReverse forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    margin-left: 20px;
  }
  to {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    margin-left: 0;
  }
  to {
    margin-left: -20px;
    opacity: 0;
  }
}

@keyframes fadeInReverse {
  from {
    opacity: 0;
    margin-left: -20px;

    transform: translate(-20px, 0);
  }
  to {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes fadeOutReverse {
  from {
    opacity: 1;
    margin-left: 0px;
  }
  to {
    margin-left: 20px;
    opacity: 0;
  }
}
