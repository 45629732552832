.copy-box-visual {
  display: flex;
  align-items: center;
  border-radius: 4px;
  line-height: 38px;
  // padding: 0 14px;
  font-size: 12px;
  // background: $cardsBgAlt;
  user-select: none;
  cursor: pointer;
}

.copy-box-visual-start {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copy-box-message {
  text-align: center;
  margin-top: 5px;
  font-size: 10px;
}
