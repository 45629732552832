// Colors
$white: #fff;
$black: #000;

$bg: #0c0c0c;
$bg40: rgba(12, 12, 12, 0.4);
$bgText: #fff;
$bgHover: #262626;

$primary: #0c0c0c;
$primaryHover: #262626;
$primaryText: #fff;

$cardsBg: #fff;
$cardsBg70: rgba(255, 255, 255, 0.7);

$cardsText: #0c0c0c;
$cardsTextLight: #505050;
$cardsBorder: #dcdcdc;
$cardsBgAlt: #f2f2f2;
$danger: #fe0133;
$footerText: #9d9d9d;

$fieldBg: #f2f2f2;
$fieldBorder: #dcdcdc;
$fieldPlaceholder: #505050;

$accent: #51df20;
$accentText: #fff;

// fonts
$font: 'Roobert', serif;

// Breakpoints
$tinybp: 37px;
$smallbp: 50px;
$smbp: 64px;
$mediumbp: 80px;
$largebp: 96px;
$xlargebp: 110px;

// Transitions
$fadeOff: left 0s 0.15s, opacity 0.15s;
$fadeOn: left 0s, opacity 0.15s;
$fadeOffR: right 0s 0.15s, opacity 0.15s;
$fadeOnR: right 0s, opacity 0.15s;
