.header {
  color: $bgText;
  padding: 18px 0;
}

.header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-connect {
  font-size: 12px;
  text-transform: uppercase;
  color: $primary;
  background: $primaryText;
  border-radius: 16px;
  line-height: 24px;
  padding: 0 12px;
  transition: 0.2s background-color;
}

.header-wallet {
  position: relative;
}

.header-wallet-button {
  border-radius: 16px;
  line-height: 24px;
  color: $bgText;
  line-height: 22px;
  border: 1px solid $bgText;
  padding: 0 12px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  transition: 0.2s color, 0.2s background-color;

  &:hover {
    background-color: $bgText;
    color: $bg;
  }

  svg {
    transition: 0.2s transform;

    .header-wallet--active & {
      transform: rotate(180deg);
    }
  }
}

.header-wallet-dropdown {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 100%;
  margin-top: 10px;
  background: $cardsBg;
  color: $cardsText;
  width: 180px;
  border-radius: 16px;
  padding: 16px;
  opacity: 0;
  right: 9999px;
  transition: $fadeOffR;

  .header-wallet--active & {
    transition: $fadeOnR;
    right: 0;
    opacity: 1;
  }
}

.header-wallet-dropdown-connection {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-wallet-dropdown-connection-indicator {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background: #5fe72f;
}

.header-wallet-dropdown-connection-address {
  font-size: 12px;
  text-transform: uppercase;
  flex: 1;
}

.header-wallet-dropdown-connection-link {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: $bg;
  color: $bgText;
  padding-bottom: 2px;
  padding-left: 2px;
}

.header-wallet-dropdown-disconnect {
  color: $danger;
  font-size: 12px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid $cardsBorder;
  display: block;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
}
