.field {
  input,
  textarea {
    border: 1px solid $fieldBorder;
    // background: $fieldBg;
    border-radius: 4px;
    display: block;
    width: 100%;

    &::placeholder {
      color: $fieldPlaceholder;
    }
  }

  input {
    line-height: 50px;
    padding: 0 16px;
  }

  textarea {
    padding: 16px;
    height: 120px;
  }
}
