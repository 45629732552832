.button {
  display: inline-block;
  padding: 0 24px;
  line-height: 50px;
  border-radius: 8px;
  color: $primaryText;
  background-color: $primary;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.2s background-color, 0.2s color;
  font-weight: 600;
  cursor: pointer;

  &--full-width {
    display: block;
    width: 100%;
    text-align: center;
  }

  &:disabled {
    pointer-events: none;
    background-color: #dcdcdc;
    color: #505050;
  }
}
