.image-field {
}

.image-field-empty-upload {
  border: 1px dashed $cardsBorder;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 170px;
}

.input-field-empty-upload-file {
  display: none;
}

.input-field-empty-upload-file-label {
  margin: 20px 0 0;
  line-height: 24px;
  border-radius: 20px;
  background-color: $cardsBgAlt;
  padding: 0 12px;
  text-transform: uppercase;
  font-size: 12px;
}

.input-field-empty-upload-url {
  font-size: 12px;
  text-decoration: underline;
  margin-top: 15px;
}

.image-field-empty-url {
  position: relative;
}

.image-field-empty-url-textarea {
  height: 170px;
  border: 1px solid $fieldBorder;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  resize: none;
}

.image-field-empty-url-cancel {
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
}

.image-field-empty-url-valid {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;

  .circle-check-circle {
    fill: #9d9d9d;
  }

  &--valid .circle-check-circle {
    fill: $cardsText;
  }
}

.image-field-preview {
  position: relative;

  img {
    width: 100%;
    border-radius: 8px;
  }
}

.image-field-file-cancel {
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: $bg40;
  transition: 0.2s background-color;
  color: white;

  &:hover {
    background-color: $bg;
  }
}
