.halo-description-content {
  overflow: hidden;

  &--truncated {
    max-height: 55px;
  }

  & > * + * {
    margin-top: 20px;
  }

  li + li {
    margin-top: 10px;
  }

  ul li {
    list-style: disc;
    margin-left: 18px;
  }

  ol li {
    list-style: decimal;
    margin-left: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 13px;
    font-weight: bold;
  }

  a {
    color: black;
    text-decoration: underline;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid $cardsBorder;
    text-align: left;
    padding: 7px;
    font-size: 13px;
  }
}

.halo-description-read {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  color: $cardsText;
}
