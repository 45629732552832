.loading {
  width: 40px;
  height: 40px;

  &.loading--absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loading-inner {
  width: 40px;
  height: 40px;
  background-color: $bgText;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  margin: 0 auto;
}
