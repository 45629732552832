.footer {
  color: $footerText;
  padding: 16px 0;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 10px;
    text-transform: uppercase;
  }
}
