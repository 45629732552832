.card {
  background: $cardsBg;
  color: $cardsText;
  border-radius: 24px;

  &--no-rounding {
    border-radius: 0;
    min-height: calc(100vh - 60px);
  }

  .footer {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
