.register-back {
  margin-top: -8px;
  margin-bottom: 8px;
}

.register-back-inner {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  text-transform: uppercase;
  font-size: 10px;
  user-select: none;
  color: $cardsText;
  cursor: pointer;
}
