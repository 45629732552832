.home-top {
  text-align: center;
  font-size: 14px;
  padding-top: 32px;
  padding-bottom: 32px;

  h2,
  p {
    position: relative;
    z-index: 2;
  }

  h2 {
    text-transform: uppercase;
    font-size: 18px;
    margin: 16px 0 4px;
  }

  p {
    margin: 0 auto;
    max-width: 260px;
  }
}

.home-primary {
  h3 {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 8px;
  }
}

.home-primary-key {
  overflow: hidden;
  background: $cardsBgAlt;
  border-radius: 4px;
  line-height: 38px;
  padding: 0 14px;
  white-space: nowrap;
  font-size: 12px;
}

.home-scan-connect {
  font-size: 10px;
  text-align: center;
  margin-top: 16px;
  line-height: 1;
  color: $cardsTextLight;
  text-transform: uppercase;
}

.home-scan-connected {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 16px;
  line-height: 1;

  svg {
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }
}

.home-chip-id {
  font-size: 12px;
  word-break: break-all;
}
