.claimable-nft {
  background-color: $bg;
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: $bgText;
  border-radius: 30px;
  padding: 0 24px;
  transition: 0.2s background-color;

  &:hover {
    background-color: $bgHover;
  }

  &--claimed {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: $bg;
    }
  }

  & + & {
    margin-top: 16px;
  }
}
